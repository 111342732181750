<template>
  <base-modal
    size="small"
    @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('generic.new_folder') }}</div>
      <div class="content">
        <form-input v-model="name">Name</form-input>
      </div>
      <div class="controls">
        <c-button
          :disabled="loading"
          @click.prevent="createNewCategory">
          {{ $t('generic.create') }}
        </c-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    data() {
      return {
        name: null,
        loading: false,
      }
    },
    methods: {
      async createNewCategory() {
        this.loading = true
        let response = await this.$api.createNewCategory(this.category.id, this.name)
        this.loading = false
        if (response && response.status === 200) {
          this.$emit('close')
          window.location.reload()
        }
      }
    },
    inject: [ '$api' ]
  }
</script>

<style lang="scss" scoped>
  .message {
    @apply
      p-4;
  }

  .content {
    @apply
      mt-4;
  }

  .controls {
    @apply
      mt-2;
  }
</style>
