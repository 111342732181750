<template>
  <base-modal
    size="dynamic"
    @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('generic.edit_folder') }}</div>
      <div class="content">
        <form-input v-model="name">Name</form-input>
        <div class="my-4">
          <c-group
            :key="idx"
            :name="attributeGroup.name"
            v-for="(attributeGroup, idx) in attributeGroups">
            <div
              class="mb-2.5"
              :key="attribute.name"
              v-for="attribute in attributeGroup.attributes">
              <form-row>
                <form-input
                  :multiple="attribute.multiple"
                  :values="findValues(attribute)"
                  @update:modelValue="handleFormInput(attribute, $event)"
                  :modelValue="findFormValue(attribute)"
                  :type="findFormType(attribute)">
                  {{ attribute.name }}
                </form-input>
              </form-row>
            </div>
          </c-group>
        </div>
      </div>
      <div class="controls">
        <c-button @click.prevent="save">{{ $t('generic.save') }}</c-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: [
    'categories',
  ],
  computed: {
    ...mapGetters({
      userAttributes: 'auth/userAttributes',
      valueDomains: 'auth/valueDomains',
      elements: 'category/elements'
    }),
    attributeGroups() {
      return this.userAttributes
        .filter(userAttr => !userAttr.readonly)
        .flatMap(attr => attr.group)
        .map(group => {
          return {
            name: group.name,
            sort: group.sort,
            attributes: this.userAttributes
              .filter(attr => attr.group?.name === group.name)
              .map(attr => {
                if (`${attr.isa}`.toLowerCase() === 'select_list') {
                  let valueDomain = this.valueDomains.find(valueDomain => valueDomain.name === attr.name)
                  if (valueDomain) {
                    attr.values = valueDomain
                    attr.multiple = valueDomain.multiple
                    if (attr.values) {
                      attr.values = attr.values.content.map(v => {
                        return {
                          ...v,
                          value: v.name,
                          label: v.name,
                        }
                      })
                      .sort((v1, v2) => v1.sort > v2.sort ? 1 : -1)
                    }
                  }
                }
                return attr
              })
          }
        })
        .filter(group => group.name)
        .filter((group, index, self) => {
          let groupIndex = self.findIndex(grp => grp.name === group.name)
          return index === groupIndex
        })
        .sort((g1, g2) => g1.sort > g2.sort ? 1 : -1)
    }
  },
  data() {
    return {
      name: null,
    }
  },
  created() {
    let categoryId = this.categories
      && this.categories[0]
        ? this.categories[0]
        : null
    if (categoryId) {
      let element = this.elements.find(element => element.id === categoryId)
      if (element) {
        this.name = element.categoryname
      }
    }
  },
  methods: {
    findFormType(attribute) {
      switch (`${attribute.isa}`.toLowerCase()) {
        case 'text_short':
          return 'text'
        case 'text_long':
          return 'textarea'
        case 'select_list':
          return 'multiselect'
      }
    },
    findFormValue(attribute) {
      let selectedCategories = this.elements.filter(element => (this.categories || []).includes(element.id))
      if (selectedCategories
          && selectedCategories.length > 0) {
        // TODO: Hier wird immer der erste Wert gewählt?
        let attributeValue = this.$helper.findAttributeValue(selectedCategories[0], attribute.name)
        if (attributeValue && typeof attributeValue === 'string') {
          if (`${attribute.isa}`.toLowerCase() === 'select_list') {
            if (attributeValue.includes('~~')) {
              attributeValue = attributeValue
                .replace('~~', '~')
                .split('~')
                .filter(attr => attr)
            } else {
              attributeValue = [attributeValue]
            }
          }
        }
        return attributeValue
      }
    },
    findValues(attribute) {
      return attribute?.values
    },
    handleFormInput(attribute, $event) {
      let selectedCategories = this.elements.filter(element => (this.categories || []).includes(element.id))
      if (selectedCategories
        && selectedCategories.length > 0) {
        for (let i = 0; i < selectedCategories.length; i++) {
          let category = selectedCategories[i]
          if (category) {
            let attrValue = $event
            if (attrValue
                && Array.isArray(attrValue)
                && attrValue.length > 1
                && `${attribute.isa}`.toLowerCase() === 'select_list') {
              attrValue = attrValue.map(attrValue => `~${attrValue}~`).join('')
            }
            let newAttribute = JSON.parse(JSON.stringify(attribute))
            newAttribute.value = attrValue
            delete newAttribute.values

            // Remove and append attr
            if (!category.changedAttributes) {
              category.changedAttributes = []
            }
            category.changedAttributes = category.changedAttributes.filter(attr => attr.name !== attribute.name)
            category.changedAttributes.push(newAttribute)

            category.attributes = category.attributes.filter(attr => attr.name !== attribute.name)
            category.attributes.push(newAttribute)
          }
        }
      }
    },
    async save() {
      let selectedCategories = this.elements.filter(element => (this.categories || []).includes(element.id))
      if (selectedCategories
        && selectedCategories.length > 0) {
        for (let i = 0; i < selectedCategories.length; i++) {
          let category = selectedCategories[i]
          if (category) {
            this.loading = true
            await this.$api.updateCategory(category.id, category.changedAttributes, this.name)
            this.loading = false
            setTimeout(() => window.location.reload(), 200)
          }
        }
      }
    },
  },
  inject: [
    '$helper',
    '$api',
  ]
}
</script>

<style lang="scss" scoped>
.message {
  @apply
  p-4
  flex
  h-full
  flex-col
  justify-between
  text-custom-secondary;

  .title {
    @apply
    text-xl
    text-center;
  }

  .content {
    @apply
    overflow-y-auto
    w-full
    h-full
    mt-4
    mb-4
    px-2;
  }
}

.controls {
  @apply
  px-4
  flex
  gap-4;
}
</style>