import store from '../store'
import moment from 'moment'

export default {
  /**
   * Installs the swagger client to every component in this application.
   * @param app
   * @param options
   * @return {Promise<void>}
   */
  async install(app, options) {
    app.provide('$api', {
      /**
       *  Prepares auth values for swagger client and
       *  returns authorization header
       */
      useHeaders(headers) {
        let auth = store.getters['auth/authCode']
        if (!auth) {
          let username = 'guest'
          let password = 'guest'
          auth = btoa(username + ':' + password)
        }

        return Object.assign({}, {
          'Accept': 'application/json',
          'Authorization': `Basic ${auth}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        }, headers || {})
      },
      /**
       * Retrieves the profile after login
       * @param username
       * @param password
       * @return {*}
       */
      login(username, password) {
        return fetch(store.getters['config/restURI'] + '/profile', {
          headers: {
            'Authorization': 'Basic ' + btoa(username + ":" + password),
            'Accept': 'application/json',
          }
        })
      },
      /**
       * Requests a password reset
       */
      requestPasswordReset(username, jumpURL) {
        // Reset current login
        store.commit('auth/setUser', null)
        store.commit('auth/setAuthCode', null)

        return fetch(store.getters['config/restURI'] + '/newpassworddata', {
          headers: this.useHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          method: 'POST',
          body: `login=${username}&htmlaction=${jumpURL}&htmlisvalid=${store.getters['config/passwordResetValidDays']}`
        })
      },
      /**
       * Send password request mail
       * @param properties
       * @return {Promise<Response>|Promise<string>}
       */
      sendPasswordResetMail(properties) {
        return fetch(store.getters['config/frontendURI'] + '/auth/password', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            url: properties.url,
            email: properties.email,
            subject: properties.subject,
            title: properties.title,
            subtitle: properties.subtitle,
            buttontitle: properties.buttontitle,
            primaryColor: properties.primaryColor,
            tertiaryColor: properties.tertiaryColor,
            secondaryColor: properties.secondaryColor,
          })
        })
      },
      /**
       * Set a new password
       * @param resetID
       * @param password
       */
      setPassword(resetID, password) {
        return fetch(store.getters['config/restURI'] + '/setpassword/' + resetID, {
          headers: this.useHeaders({
            'Accept': 'text/plain',
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          method: 'POST',
          body: `password=${password}`
        })
      },
      /**
       * Creates a new user
       * @param payload as Object
       * @param jumpURL
       * @param registerRole
       */
      register(payload, jumpURL, registerRole) {
        const roleId = (typeof registerRole === 'string' || typeof registerRole === 'number')
          ? registerRole
          : registerRole.id
        let validFrom = null
        let validTo = null
        if (typeof registerRole === 'object') {
          validFrom = registerRole.valid_from
          validTo = registerRole.valid_to
        }

        // Set the login to email
        payload.login = payload.email
        payload.lastName = payload.lastname
        payload.firstName = payload.firstname
        payload.mobilePhone = payload.mobilephone

        return fetch(store.getters['config/restURI'] + '/register', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Authorization': 'Basic ' + btoa(store.getters['config/registerUser'] + ":" + store.getters['config/registerUser']),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `invite=no&data=${JSON.stringify({
            ...payload,
            mainRole: roleId,
            locale: store.getters['config/language'],
          })}`
        })
      },
      /**
       * Retrieves a categories by given parent id
       * @param parentId
       * @param sortType
       * @param sortDirection
       */
      async findCategories(parentId, sortType, sortDirection) {
        sortType = sortType || 'sortval'
        sortDirection = sortDirection || 'desc'
        
        let headers = this.useHeaders()
        delete headers['Content-Type']
        const query = `?q=parent:${parentId}&s=${sortType} ${sortDirection}`
        let categoriesResponse = await fetch(store.getters['config/restURI'] + '/categories' + query, {headers})
        if (categoriesResponse) {
          categoriesResponse = await categoriesResponse.json()
          return categoriesResponse.results
        }
      },
      /**
       * Retrieves categories by given query
       * @param queryInput
       * @param sortType
       * @param sortDirection
       */
      async searchCategories(queryInput, sortType, sortDirection) {
        sortType = sortType || 'sortval'
        sortDirection = sortDirection || 'desc'

        let headers = this.useHeaders()
        delete headers['Content-Type']
        let z = encodeURIComponent(queryInput)
        const query = `?q=${z}&s=${sortType} ${sortDirection}`
        let categoriesResponse = await fetch(store.getters['config/restURI'] + '/categories' + query, {headers})
        if (categoriesResponse) {
          categoriesResponse = await categoriesResponse.json()
          return categoriesResponse.results
        }
      },
      /**
       *  Retrieves a category by given id
       */
      async findCategory(id) {
        let categoryResponse = await fetch(store.getters['config/restURI'] + '/category/' + id, {
          headers: this.useHeaders()
        })
        if (categoryResponse) {
          categoryResponse = await categoryResponse.json()
          return categoryResponse
        }
      },
      
      async moveCategory(targetCategoryId, categories) {
        await fetch(store.getters['config/restURI'] + '/category/' + targetCategoryId, {
          headers: this.useHeaders({
            'Accept': 'text/plain',
          }),
          method: 'POST',
          body: new URLSearchParams({
            'move': JSON.stringify(categories)
          })
          
        })
      },
  
      async copyCategory(targetCategoryId, categories) {
        await fetch(store.getters['config/restURI'] + '/category/' + targetCategoryId, {
          headers: this.useHeaders({
            'Accept': 'text/plain',
          }),
          method: 'POST',
          body: new URLSearchParams({
            'copy': JSON.stringify(categories)
          })
      
        })
      },
      /**
       * @param categoryId
       * @param status

       */
      async setMeetingStatus(categoryId, status) {
        return await fetch(store.getters['config/restURI'] + '/category/' + categoryId, {
          headers: this.useHeaders({
            'Accept': 'text/plain',
          }),
          method: 'POST',
          body: new URLSearchParams({
            '_meetingstatus': status
          })
        })
      },
      /**
       * @param categoryId
       * @returns {Promise<*|Response>}
       */
      async findCategoryUsers(categoryId) {
        let category = await this.findCategory(categoryId)
        let users = await this.findUsers()
        if (category && users) {
          let connectedRoles = (category.roles || [])
          return users.filter(user => connectedRoles.includes(user.mainRole) || user.mainRole === '1')
        }
      },
      /**
       * Retrieves all users of the MAM system
       */
      async findUsers() {
        let response = await fetch(store.getters['config/restURI'] + '/users?limit=500', {
          headers: this.useHeaders()
        })
        if (response && response.ok) {
          response = await response.json()
          return response.result
        }
      },
      /**
       * @param categoryId
       * @param properties
       * @returns {Response<void>}
       */
      async createMediaCategory(categoryId, properties) {
        let urlParams = new URLSearchParams()
        urlParams.append('data', JSON.stringify(properties))
        urlParams.append('type', 'media')

        return await fetch(store.getters['config/restURI'] + '/category/' + categoryId, {
          method: 'PUT',
          headers: this.useHeaders({ Accept: 'text/plain' }),
          body: urlParams
        })
      },
      async uploadToMediaCategory(categoryId, file) {
        let formData = new FormData()
        let auth = store.getters['auth/authCode']

        formData.append('files', file)
        formData.append('categoryid', categoryId)
        formData.append('attributeid', '1')

        return await fetch(store.getters['config/restURI'] + '/document/' + categoryId + '/1', {
          method: 'POST',
          headers: {
            'Accept': 'text/plain',
            'Authorization': `Basic ${auth}`,
          },
          body: formData
        })
      },
      async updateCategory(categoryId, attributes, categoryName) {
        if (!attributes) {
          attributes = []
        }
        
        attributes = attributes
          .filter(attr => attr.id !== '1')
          .map(attr => {
            let value = attr.value
            if (Array.isArray(value)) {
              value = value.join('')
            }
            return {
              attribute: attr.name,
              value,
            }
          })
        attributes.push({categoryname: categoryName})
        
        let params = new URLSearchParams()
        params.append('wait', 'yes')
        if (attributes && attributes.length > 0) {
          params.append('_attributevalues', JSON.stringify(attributes))
        }
        return await fetch(store.getters['config/restURI'] + '/category/' + categoryId, {
          method: 'POST',
          headers: this.useHeaders({
            'Accept': 'text/plain',
          }),
          body: params
        })
      },
      /**
       *
       * @param customerId
       */
      /**
       * Sends to the users an invitation mail
       * @param properties
       * @returns {Promise<Response|string>}
       */
      async sendInviteMail(properties) {
        if (properties.fromDate !== '?' && properties.untilDate !== '?') {
          properties.fromDate = moment(properties.fromDate).format('DD.MM.YYYY HH:mm:ss');
          properties.untilDate = moment(properties.untilDate).format('DD.MM.YYYY HH:mm:ss');
        }

        return fetch(store.getters['config/frontendURI'] + '/meetings/invite', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            fromDate: properties.fromDate,
            untilDate: properties.untilDate,
            meetingURL: properties.meetingURL,
            meetingTitle: properties.meetingTitle,
            message: properties.message,
            title: properties.title,
            subtitle: properties.subtitle,
            buttontitle: properties.buttontitle,
            emails: properties.emails,
            primaryColor: properties.primaryColor,
            tertiaryColor: properties.tertiaryColor,
            secondaryColor: properties.secondaryColor,
          })
        })
      },

      async loginIntoRocketChat(authCode, email, room, roomType, userId) {
        let auth = window.atob(authCode).split(':')
        let username = userId
          .replace('@', '-at-')
        return fetch(store.getters['config/frontendURI'] + '/chat/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            room,
            email,
            roomType,
            password: auth[1],
            username,
          })
        })
      },

      async findOrCreateChatRoom(room, roomType) {
        return fetch(store.getters['config/frontendURI'] + '/chat/rooms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            room,
            roomType,
          })
        })
      },
      
      async createNewCategory(parentCategoryId, name) {
        let urlParams = new URLSearchParams()
        urlParams.append('data', JSON.stringify({_category: name}))
        urlParams.append('type', 'folder')
  
        return await fetch(store.getters['config/restURI'] + '/category/' + parentCategoryId, {
          method: 'PUT',
          headers: this.useHeaders({ Accept: 'text/plain' }),
          body: urlParams
        })
      },
      
      async deleteCategory(categoryId) {
        return await fetch(store.getters['config/restURI'] + '/category/' + categoryId, {
          method: 'DELETE',
          headers: this.useHeaders({
            'Accept': 'text/plain',
          })
        })
      },
      
      async findValueDomains(attributes) {
        let queryParams = `q=${encodeURIComponent(JSON.stringify(attributes))}`
        let response = await fetch(store.getters['config/restURI'] + '/valuedomains?' + queryParams, {
          headers: this.useHeaders()
        })
        if (response && response.ok) {
          return await response.json()
        }
      }
    })
  }
}