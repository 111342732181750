import {createApp, defineAsyncComponent} from 'vue'
import { createI18n } from 'vue-i18n'
import router from './router'
import store from './store'
import VueRest from './plugins/rest'
import VueHelper from './plugins/helper'
import RouterMixin  from './mixin/router'
import VueToast from '@meforma/vue-toaster'

// Components
import Icon from './components/global/Icon'
import CButton from './components/global/CButton'
import Breadcrumb from './components/Breadcrumb'
import Breadcrumbs from './components/Breadcrumbs'
import FolderElement from './components/FolderElement'
import FormRow from './components/global/forms/FormRow'
import FolderContainer from './components/FolderContainer'
import FormInput from './components/global/forms/FormInput'
import FormMessage from './components/global/forms/FormMessage'
import ClientSelector from './components/navigation/ClientSelector'
import NavigationLayout from './components/layouts/NavigationLayout'
import NavigationEntry from './components/navigation/NavigationEntry'
import DefaultLayout from './components/layouts/content/DefaultLayout'
import CenteredFormLayout from './components/layouts/CenteredFormLayout'
import NavigationContainer from './components/navigation/NavigationContainer'
import NavigationFooter from './components/navigation/NavigationFooter'
import BaseModal from './components/global/modals/BaseModal'
import DescriptiveContent from './components/DescriptiveContent'
import ContentSection from './components/ContentSection'
import LayoutContainer from './components/layouts/LayoutContainer'
import ContentContainer from './components/layouts/ContentContainer'
import Display from './components/layouts/digital-signage/Display'
import ConfirmModal from './components/global/modals/ConfirmModal'
import InviteModal from './components/views/meeting/InviteModal'
import UserList from './components/views/meeting/UserList'
import User from './components/views/meeting/User'
import CDropdown from './components/global/CDropdown'
import CategoryUsersModal from './components/views/category/CategoryUserModal'
import Chat from './components/Chat'
import NewCategoryModal from './components/views/category/NewCategoryModal'
import SortCategoryModal from './components/views/category/SortCategoryModal'
import EditCategoryModal from './components/views/category/EditCategoryModal'
import CGroup from './components/global/CGroup'

// Language files
import de from './locales/de'
import en from './locales/en'

const run = async () => {
  // Loads the configuration file
  await store.dispatch('config/loadConfiguration')

  // Create i18n-languages module
  const i18n = createI18n({
    locale: store.getters['config/language'],
    fallbackLocale: 'en',
    messages: {
      de,
      en,
    }
  })

  // Run the app
  createApp(defineAsyncComponent(() => import('@/App.vue')))
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueRest)
    .use(VueToast)
    .use(VueHelper)
    .mixin(RouterMixin)
    .component('chat', Chat)
    .component('icon', Icon)
    .component('form-row', FormRow)
    .component('c-button', CButton)
    .component('c-group', CGroup)
    .component('c-dropdown', CDropdown)
    .component('base-modal', BaseModal)
    .component('form-input', FormInput)
    .component('breadcrumb', Breadcrumb)
    .component('breadcrumbs', Breadcrumbs)
    .component('form-message', FormMessage)
    .component('default-layout', DefaultLayout)
    .component('folder-element', FolderElement)
    .component('client-selector', ClientSelector)
    .component('content-section', ContentSection)
    .component('layout-container', LayoutContainer)
    .component('folder-container', FolderContainer)
    .component('navigation-entry', NavigationEntry)
    .component('navigation-layout', NavigationLayout)
    .component('navigation-footer', NavigationFooter)
    .component('descriptive-content', DescriptiveContent)
    .component('centered-form-layout', CenteredFormLayout)
    .component('navigation-container', NavigationContainer)
    .component('content-container', ContentContainer)
    .component('display', Display)
    .component('confirm-modal', ConfirmModal)
    .component('invite-modal', InviteModal)
    .component('user-list', UserList)
    .component('user', User)
    .component('category-user-modal', CategoryUsersModal)
    .component('new-category-modal', NewCategoryModal)
    .component('sort-category-modal', SortCategoryModal)
    .component('edit-category-modal', EditCategoryModal)
    .mount('#app')
}
run().then(() => console.log('App has been launched'))
