<template>
  <component
    :is="component"
    v-if="component"
    :category="category"
    :wide="showInFullscreen"
    :breadcrumb="hasBreadcrumb">
    <slot />
  </component>
</template>

<script>
  import {defineAsyncComponent, markRaw} from 'vue'

  export default {
    props: [
      'category',
      'showInFullscreen',
    ],
    computed: {
      hasBreadcrumb() {
        return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('Breadcrumb')
      },
    },
    data() {
      return {
        component: null,
      }
    },
    methods: {
      /**
       * Resolves the correct component to show contents
       * @param category
       */
      async resolveComponent(category) {
        // Determine the current layout
        // (Page = CMS-Template-Attribute === 'Page' OR null)
        // (SplitView = CMS-Template-Attribute === 'SplitView')
        // (HomePageLayout = CMS-Template-Attribute === 'Page' and has Child elements)
        let templateAttr = category.attributes?.find(attr => attr.name === 'CMS-Template')
        switch (templateAttr?.value?.toLowerCase()) {
          case 'digital signage':
            return markRaw(defineAsyncComponent(() => import('./content/DigitalSignageLayout')))
          case 'splitview':
            return markRaw(defineAsyncComponent(() => import('./content/SplitViewLayout')))
          case 'page':
          default:
            let templateAttrValue = `${this.$helper.findAttributeValue(this.category, 'CMS-Template')}`.toLowerCase()
            if (category.childrencount > 0
              && templateAttrValue === 'page') {
              return markRaw(defineAsyncComponent(() => import('./content/HomepageLayout')))
            }
            return markRaw(defineAsyncComponent(() => import('./content/DefaultLayout')))
        }
      },
    },
    watch: {
      category: {
        deep: true,
        immediate: true,
        async handler() {
          if (this.category) {
            this.component = await this.resolveComponent(this.category)
          }
        }
      }
    },
    inject: [
      '$helper',
    ]
  }
</script>