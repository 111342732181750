<template>
  <div class="centered-form">
    <form class="form">
      <div class="w-full flex justify-center">
        <img
          :src="logo"
          v-if="logo && $route.query.showNavigation === 'false'">
      </div>
      <div
        v-if="title"
        class="title">
        {{ title }}
      </div>
      <slot/>
    </form>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'title',
    ],
    computed: {
      ...mapGetters({
        logo: 'app/logo'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .centered-form {
    @apply
    p-2
    flex
    md:p-0
    w-full
    h-full
    md:flex-col
    items-center
    text-gray-800
    justify-center
    bg-custom-background;

    .form {
      @apply
      p-6
      h-auto
      w-full
      rounded
      md:border
      max-w-xl
      max-h-full
      md:shadow-xl
      overflow-y-auto
      bg-custom-primary;

      .title {
        @apply
        my-3
        text-xl
        font-medium;
      }
    }
  }
</style>